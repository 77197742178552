import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import Pages404 from "../../pages/Extra Pages/pages-404";
import { dcrypt, hasRole, uInfo, uRole } from "../../useToken";



const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  requiredRoles,
  role,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      const search = props.location.search;
      const u = new URLSearchParams(search).get('u');

      if (props.location.pathname == '/client/form/details') {
        if (dcrypt(u) == 0) {
          props.history.push(`/complete/profile${search}`, { from: props.location });
        } else {
          if (!localStorage.getItem("authUser")) {
            return (
              <Redirect
                to={{ pathname: "/login", state: { from: props.location } }}
              />
            )
          } else {
            return (
              <Redirect
                to={{ pathname: "/client/profile" }}
              />
            )
          }
        }
      } else {
        if (isAuthProtected && !localStorage.getItem("authUser")) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }
      }


      return (
        <Layout>
          {
            isAuthProtected && !hasRole(uInfo(), requiredRoles) ?
              <Pages404 />
              :
              <Component key={props.match.url} theme={2} role={uRole()} {...props} />
          }
        </Layout>
      )
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware
