import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardTitle,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import Loader from "react-loader";
import { ApiUrl } from "../../config";
import { uToken } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";
import ShakeHand from "./../../assets/images/small/ShakeHand.png";
import Cash from "./../../assets/images/small/cash-icon.png";
import Gulak from "./../../assets/images/profile-icons/gulak.png";
import ItemShape from "./../../assets/images/shape.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-bootstrap";
import swal from "sweetalert";
import GoalAchivenmnet from "../../components/Custom/GoalAchivenmnet";
import moment from "moment";

const Roi = (props) => {
  console.log("Roi  props", props);
  const [loaded, setLoaded] = useState(false);
  const [revenue, setRevenue] = useState(props.currentRevenue);
  const [profit, setProfit] = useState(props.currentProfit);
  const [question, setQuestion] = useState([]);
  const [toggleSwitch, settoggleSwitch] = useState(true);
  const [toggleConversionSwitch, settoggleConversionSwitch] = useState(true);
  const [toggleTrackerSwitch, settoggleTrackerSwitch] = useState(false);
  const [toggleDollorSwitch, settoggleDollorSwitch] = useState(true);

  const [customer_conversion_calculation] = useState(
    props.investment.customer_conversion_calculation
  );
  const [conversation, setConversation] = useState({});
  const [conversationModal, setConversationModal] = useState(false);
  const [path_way_progression, setPathWayProgression] = useState(
    props.pathWayProgression
  );
  const [customer_conversion, setCustomerConversion] = useState(
    props.customerConversion
  );
  const [selectedMonth, setSelectedMonth] = useState(new moment().format("MM"));
  const [roiBreakdown, setRoiBreakdown] = useState({
    total_profit_gain: 0,
    total_profit_value: 0,
    coaching_fee_ytd_gain: 0,
    coaching_fee_ytd_value: 0,
  });

  useEffect(() => {
    //console.log(props.pathWayProgressionCalculation.roi_breakdown, 'roi_breakdown')
    setRoiBreakdown(props.pathWayProgressionCalculation.roi_breakdown ?? {});
    setLoaded(true);
    setQuestion(props.questions);
    getConversationBreakdownByMonth(new moment().format("MM"));
  }, []);
  function NumberWithCommas(x) {
    let y = Math.trunc(x);
    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function NumberWithCommas(x) {
    let y = Math.trunc(x);
    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoaded(false);
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();
    submitData.append("assessment_id", props.assessment);
    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }

    fetch(`${ApiUrl}` + "submit/assessment/roi", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          props.getAssessment();
          // toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
        setLoaded(true);
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
        setLoaded(true);
      });
  };

  const getConversationBreakdownByMonth = (month = "0") => {
    fetch(`${ApiUrl}` + "get/conversation/breackdown/month", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ assessment_id: props.assessment, month: month }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setConversation(response.data.record);
          setSelectedMonth(month);
          // toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleConversationBreakdownSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();
    submitData.append("assessment_id", props.assessment);
    let month = 0;
    for (let [key, value] of formData.entries()) {
      if (key == "calculation_month") {
        month = value;
      }
      submitData.append(key, value);
    }

    fetch(`${ApiUrl}` + "submit/conversation/breakdown", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          closeConversationModal(month);
          swal({
            title: response.message,
            icon: "success",
          });
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const closeConversationModal = (month = 0) => {
    setSelectedMonth(selectedMonth);
    getConversationBreakdownByMonth(selectedMonth);
    setConversationModal(false);
  };

  // console.log(customer_conversion_calculation, 'customer_conversion_calculation')
  function isWhatPercentOf(numA, numB) {
    let a = (numA / numB) * 100;
    if (a >= 100) {
      return 100;
    }
    return (numA / numB) * 100;
  }

  return (
    <React.Fragment>
      <div style={{ background: "transparent" }}>
        <Loader loaded={loaded}></Loader>
        <Row>
          <Col md={6}>
            <GoalAchivenmnet
              name={props.name}
              assessment={props.assessment}
              slug={props.slug}
              canEdit={props.canEdit}
            />
          </Col>
          <Col md={6}>
            <section className="breakthrough sub-nav-bg mb-0 text-center">
              <h5 className="mb-0">Pathway Progression</h5>
            </section>
            <div
              className="card card-body Breakdown PathwayProgressionTwo system-custom-border ProgresstionRoiTracker PathwayProgression"
              style={{ justifyContent: "unset" }}
            >
              <div className="ProfileBreakDown ProgressionPathway">
                {path_way_progression.length > 0 &&
                  path_way_progression.map((row, i) => {
                    return (
                      <div className="ROiPercents mb-2">
                        <div className="revenue pull-left w-100">
                          <p>{row.cat_name}</p>
                        </div>
                        <div className="SectionHeader RoiPage ">
                          <div className="Percents">
                            {row.total_complete > 0 &&
                              row.total_complete < 100 && (
                                <h4
                                  className="GrayBG justify-content-center"
                                  style={{
                                    background: "#008037",
                                    width: `${
                                      row.total_complete > 0
                                        ? row.total_complete + "%"
                                        : "0%"
                                    }`,
                                  }}
                                >
                                  <span className="pull-left">
                                    {" "}
                                    {row.total_complete}%
                                  </span>
                                </h4>
                              )}
                            {row.total_remaining > 0 && (
                              <h4
                                className="GreenBG justify-content-center"
                                style={{
                                  background: "#041934",
                                  textAlign: `${
                                    row.total_remaining > 0 &&
                                    row.total_complete == 0
                                      ? "center"
                                      : "right"
                                  }`,
                                  width: `100%`,
                                }}
                              >
                                {row.total_remaining}% Remaining
                              </h4>
                            )}
                            {row.total_complete == 100 && (
                              <h4
                                className="GreenBG text-center FullBG"
                                style={{
                                  background: "#f4bc33",
                                  width: `${
                                    row.total_complete > 0
                                      ? row.total_complete + "%"
                                      : "0%"
                                  }`,
                                }}
                              >
                                <span className="pull-left">
                                  {" "}
                                  {row.total_complete}%
                                </span>
                              </h4>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </Col>
        </Row>

        {props.investment && (
          <>
            <Row>
              <Col md={6}>
                <section className="conversation breakthrough sub-nav-bg mb-0 text-center">
                  <div className="MonthlyDropDown ">
                    <select
                      value={selectedMonth}
                      id="monthSelection"
                      onChange={(e) =>
                        getConversationBreakdownByMonth(e.target.value)
                      }
                      className="form-control"
                    >
                      <option value={"0"}>All</option>
                      <option value={"01"}>January</option>
                      <option value={"02"}>February</option>
                      <option value={"03"}>March</option>
                      <option value={"04"}>April</option>
                      <option value={"05"}>May</option>
                      <option value={"06"}>June</option>
                      <option value={"07"}>July</option>
                      <option value={"08"}>August</option>
                      <option value={"09"}>September</option>
                      <option value={"10"}>October</option>
                      <option value={"11"}>November</option>
                      <option value={"12"}>December</option>
                    </select>
                  </div>

                  <h5 className="mb-0">Conversion Breakdown</h5>
                  <button
                    disabled={!props.canEdit ? true : false}
                    onClick={() => {
                      setConversationModal(true);
                      getConversationBreakdownByMonth(selectedMonth);
                    }}
                    className="conversation-break-down--edit-btn btn btn-link btn-sm text-white btn-block"
                  >
                    <i className="fas fa-edit"></i>
                  </button>
                </section>
                <div className="card card-body Breakdown RoiBreakDown system-custom-border">
                  <Row>
                    <Col lg={6}>
                      <div className="SalesCounter">
                        <div className="CountBox mt-5">
                          <div className="Counters">
                            <div className="oIcons">
                              <i className="fas fa-user"></i>
                            </div>
                            <div className="Count-LastYear">
                              <span className="CountNumber">
                                {NumberWithCommas(conversation.new_customers)}
                              </span>
                              <span className="CountDesc">
                                New Deals Closed
                              </span>
                            </div>
                          </div>
                          <div className="Counters">
                            <div className="oIcons">
                              <i className="fas fa-user-plus"></i>
                            </div>
                            <div className="Count-LastYear">
                              <span className="CountNumber">
                                {NumberWithCommas(conversation.new_leads)}
                              </span>
                              <span className="CountDesc">New Leads Open</span>
                            </div>
                          </div>
                          <div className="Counters">
                            <div className="oIcons Dollar">
                              {/* <i className="fas fa-dollar-sign"></i> */}
                              <img src={Cash}></img>
                            </div>
                            <div className="Count-LastYear">
                              <span className="CountNumber">
                                {props.currency}
                                {NumberWithCommas(
                                  conversation.new_customers *
                                    (conversation.avg_sales_price == 0
                                      ? props.investment
                                          ?.customer_conversion_calculation
                                          .avg_ticket_price
                                      : conversation.avg_sales_price)
                                )}
                              </span>
                              <span className="CountDesc">Deal Value</span>
                            </div>
                          </div>
                          <div className="Counters">
                            <div className="oIcons Percent">
                              <i className="fas fa-percent"></i>
                            </div>
                            <div className="Count-LastYear">
                              <span className="CountNumber">
                                {conversation.new_leads > 0
                                  ? Math.round(
                                      (conversation.new_customers /
                                        conversation.new_leads) *
                                        100
                                    )
                                  : 0}
                                %
                              </span>
                              <span className="CountDesc">Conversion Rate</span>
                            </div>
                          </div>
                          <div className="Counters">
                            <div className="oIcons Dollar bg-danger">
                              {/* <i className="fas fa-dollar-sign"></i> */}
                              <img src={Cash}></img>
                            </div>
                            <div className="Count-LastYear">
                              <span className="CountNumber">
                                {props.currency}
                                {NumberWithCommas(
                                  conversation.lost_leads *
                                    conversation.avg_sales_price
                                )}
                              </span>
                              <span className="CountDesc">Missed Revenue</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="LeadsSales pt-5">
                        <div className="form-check form-switch mb-3 toogle-sales ConversionSwith mt-2">
                          <label>
                            {toggleConversionSwitch
                              ? "Lead Source"
                              : "Lead Estimation"}
                          </label>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitch2"
                            onClick={(e) => {
                              settoggleConversionSwitch(
                                !toggleConversionSwitch
                              );
                            }}
                          />
                        </div>

                        {toggleConversionSwitch ? (
                          <div className="CountBox mt-5">
                            <div className="Counters CustomerBreakDown">
                              <div className="Count-LastYear">
                                <span className="CountDesc">Phone Calls</span>
                                <span className="CountNumber">
                                  {NumberWithCommas(conversation.phone_calls)}
                                </span>
                              </div>
                            </div>

                            <div className="Counters CustomerBreakDown">
                              <div className="Count-LastYear">
                                <span className="CountDesc">Web Forms</span>
                                <span className="CountNumber">
                                  {NumberWithCommas(conversation.web_forms)}
                                </span>
                              </div>
                            </div>
                            <div className="Counters CustomerBreakDown">
                              <div className="Count-LastYear">
                                <span className="CountDesc">Word of Mouth</span>
                                <span className="CountNumber">
                                  {NumberWithCommas(conversation.word_of_mouth)}
                                </span>
                              </div>
                            </div>
                            <div className="Counters CustomerBreakDown">
                              <div className="Count-LastYear">
                                <span className="CountDesc">Referral</span>
                                <span className="CountNumber">
                                  {NumberWithCommas(conversation.referral)}
                                </span>
                              </div>
                            </div>
                            <div className="Counters CustomerBreakDown">
                              <div className="Count-LastYear">
                                <span className="CountDesc">Partnership</span>
                                <span className="CountNumber">
                                  {NumberWithCommas(conversation.partnership)}
                                </span>
                              </div>
                            </div>

                            <div className="Counters CustomerBreakDown">
                              <div className="Count-LastYear">
                                <span className="CountDesc">Social Media</span>
                                <span className="CountNumber">
                                  {NumberWithCommas(conversation.social_media)}
                                </span>
                              </div>
                            </div>

                            <div className="Counters CustomerBreakDown">
                              <div className="Count-LastYear">
                                <span className="CountDesc">
                                  PPC Advertising
                                </span>
                                <span className="CountNumber">
                                  {NumberWithCommas(
                                    conversation.ppc_advertising
                                  )}
                                </span>
                              </div>
                            </div>

                            <div className="Counters CustomerBreakDown">
                              <div className="Count-LastYear">
                                <span className="CountDesc">
                                  Web Search (Organic)
                                </span>
                                <span className="CountNumber">
                                  {NumberWithCommas(conversation.web_search)}
                                </span>
                              </div>
                            </div>

                            <div className="Counters CustomerBreakDown">
                              <div className="Count-LastYear">
                                <span className="CountDesc">
                                  Email Referral (Exisiting Customer)
                                </span>
                                <span className="CountNumber">
                                  {NumberWithCommas(
                                    conversation.email_referral
                                  )}
                                </span>
                              </div>
                            </div>

                            <div className="Counters CustomerBreakDown">
                              <div className="Count-LastYear">
                                <span className="CountDesc">
                                  Email Campaign (New Customer)
                                </span>
                                <span className="CountNumber">
                                  {NumberWithCommas(
                                    conversation.email_campaign
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="CountBox mt-5">
                            <div className="Counters">
                              <div className="oIcons">
                                <i className="fas fa-user-plus"></i>
                                {/* <span className="PlusUser"><i className="fas fa-plus"></i></span> */}
                              </div>
                              <div className="Count-LastYear">
                                <span className="CountNumber">
                                  {NumberWithCommas(
                                    customer_conversion_calculation.toggle_1
                                      .new_leads
                                  )}
                                </span>
                                <span className="CountDesc">New Leads</span>
                              </div>
                            </div>
                            <div className="Counters">
                              <div className="oIcons Dollar">
                                <i className="fas fa-plus"></i>
                              </div>
                              <div className="Count-LastYear">
                                <span className="CountNumber">
                                  {NumberWithCommas(
                                    customer_conversion_calculation.toggle_1
                                      .leads_per_month
                                  )}
                                </span>
                                <span className="CountDesc">Leads / Month</span>
                              </div>
                            </div>
                            <div className="Counters">
                              <div className="oIcons">
                                <img src={ShakeHand}></img>
                              </div>
                              <div className="Count-LastYear">
                                <span className="CountNumber">
                                  {NumberWithCommas(
                                    customer_conversion_calculation.toggle_1
                                      .sales_12_month
                                  )}
                                </span>
                                <span className="CountDesc">Sales</span>
                              </div>
                            </div>
                            <div className="Counters">
                              <div className="oIcons Dollar">
                                {/* <i className="fas fa-dollar-sign"></i> */}
                                <img src={Cash}></img>
                              </div>
                              <div className="Count-LastYear">
                                <span className="CountNumber">
                                  {NumberWithCommas(
                                    customer_conversion_calculation.toggle_1
                                      .sales_per_month
                                  )}
                                </span>
                                <span className="CountDesc">Sales / Month</span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={6}>
                <section className="breakthrough sub-nav-bg mb-0 text-center">
                  <h5 className="mb-0">ROI Breakdown</h5>
                </section>
                <div className="card card-body Breakdown ROI Breakdown system-custom-border">
                  <form
                    onSubmit={handleSubmit}
                    method="post"
                    encType="multipart/form-data"
                    className="form-horizontal"
                    id="selection-myform-roi"
                  >
                    <div className="row mt-0 mb-3">
                      <Col lg={5} className="offset-lg-1">
                        <Row>
                          <label
                            htmlFor="initial_investment"
                            className="col-sm-7 col-form-label"
                            style={{ textAlign: "right" }}
                          >
                            Initial Investment:
                          </label>
                          <div className="col-sm-5 px-0">
                            <span className="p-2">
                              {props.currency}
                              {NumberWithCommas(
                                props.investment.initial_investment
                              )}
                            </span>
                            {/* <input defaultValue={props.investment.initial_investment} type="text" className="form-control" name="initial_investment" /> */}
                          </div>
                        </Row>
                      </Col>
                      <Col lg={5}>
                        <Row>
                          <label
                            htmlFor="revenue_share"
                            className="col-sm-6 col-form-label"
                            style={{ textAlign: "right" }}
                          >
                            Revenue Share:
                          </label>
                          <div className="col-sm-6 px-0">
                            <span className="p-2">
                              %
                              {NumberWithCommas(props.investment.revenue_share)}
                            </span>

                            {/* <input defaultValue={props.investment.revenue_share} type="text" className="form-control" name="revenue_share" /> */}
                          </div>
                        </Row>
                      </Col>
                    </div>
                    <div className="row mt-3 mb-3">
                      <Col lg={5} className="offset-lg-1">
                        <Row>
                          <label
                            htmlFor="monthly_investment"
                            className="col-sm-7 col-form-label"
                            style={{ textAlign: "right" }}
                          >
                            Monthly Retainer:
                          </label>
                          <div className="col-sm-5 px-0">
                            <span className="p-2">
                              {props.currency}
                              {NumberWithCommas(
                                props.investment.monthly_investment
                              )}
                            </span>

                            {/* <input defaultValue={props.investment.monthly_investment} type="text" className="form-control" name="monthly_investment" /> */}
                          </div>
                        </Row>
                      </Col>
                      <Col lg={5}>
                        <Row>
                          <div className="col-sm-12">
                            {/* <button className="btn btn-primary saveBtn">Save</button> */}
                          </div>
                        </Row>
                      </Col>
                    </div>
                  </form>

                  {/* <Row>
                      <Col lg={10} className="space-between d-flex offset-lg-1 mt-1">
                        <h4 className="m-0">&nbsp;</h4>
                        <h3 className="m-0">+0% ROI</h3>
                      </Col>
                    </Row> */}
                  <div className="row">
                    <div className=" Breakdown">
                      <div className="ROiPercents mb-2 mt-2">
                        <div className="revenue pull-left">
                          <p>Total Profit Gain</p>
                        </div>
                        <div className="SectionHeader RoiPage ">
                          <div className="Percents">
                            <h4
                              className="GreenBG text-start"
                              title={
                                props.currency +
                                NumberWithCommas(roiBreakdown.total_profit_gain)
                              }
                              style={{
                                background: "#008037",
                                width: `${isWhatPercentOf(
                                  roiBreakdown.total_profit_gain,
                                  Number(props.AnnualNetProfit)
                                )}%`,
                              }}
                            >
                              {props.currency}
                              {NumberWithCommas(roiBreakdown.total_profit_gain)}
                            </h4>
                            <div className="TotalPercent">
                              <h4 className="text-end">
                                <span className="percentageSign pull-right">
                                  {props.currency}
                                  {NumberWithCommas(
                                    Number(props.AnnualNetProfit)
                                  )}
                                </span>
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className="revenue pull-right">
                          <p>
                            {props.currency}
                            {NumberWithCommas(
                              props.AnnualNetProfit -
                                Number(roiBreakdown.total_profit_gain)
                            )}{" "}
                            Remaining
                          </p>
                        </div>
                      </div>

                      <div className="ROiPercents mb-2 mt-2">
                        <div className="revenue pull-left">
                          <p>Coaching Fee YTD</p>
                        </div>
                        <div className="SectionHeader RoiPage ">
                          <div className="Percents">
                            <h4
                              className="GreenBG text-start"
                              style={{
                                background: "#008037",
                                width: `${isWhatPercentOf(
                                  roiBreakdown.coaching_fee_ytd_gain,
                                  Number(roiBreakdown.coaching_fee_ytd_value)
                                )}%`,
                              }}
                              title={
                                props.currency +
                                NumberWithCommas(
                                  Number(roiBreakdown.coaching_fee_ytd_gain)
                                )
                              }
                            >
                              {props.currency}
                              {NumberWithCommas(
                                Number(roiBreakdown.coaching_fee_ytd_gain)
                              )}
                            </h4>
                            <div className="TotalPercent">
                              <h4 className="text-end">
                                <span className="percentageSign pull-right">
                                  {props.currency}
                                  {NumberWithCommas(
                                    Number(roiBreakdown.coaching_fee_ytd_value)
                                  )}
                                </span>
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className="revenue pull-right">
                          <p>
                            {props.currency}
                            {NumberWithCommas(
                              roiBreakdown.coaching_fee_ytd_value -
                                Number(roiBreakdown.coaching_fee_ytd_gain)
                            )}{" "}
                            Remaining
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Row>
                    <Col
                      lg={10}
                      className="space-between d-flex offset-lg-1 mt-1 BGYellow"
                    >
                      <h5 className="m-0">Net Return</h5>
                      <h4 className="m-0">
                        {roiBreakdown.coaching_fee_ytd_gain > 0
                          ? Math.round(
                              (Number(roiBreakdown.total_profit_gain) /
                                Number(roiBreakdown.coaching_fee_ytd_gain)) *
                                100
                            )
                          : 0}
                        %
                      </h4>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </>
        )}

        <Modal
          isOpen={conversationModal}
          fade={true}
          size="lg"
          className="FinancialBreakdown"
          toggle={() => closeConversationModal()}
          centered={true}
        >
          <div className="card card-body">
            <div className="modalQuestion">
              <button className="mb-3 btn btn-primary BtnCenter"></button>
              <button
                onClick={() => closeConversationModal()}
                className="mb-3 btn btn-link pull-right BtnCenterClose"
              >
                <i className="fas fa-times"></i>
              </button>

              <form
                method="post"
                onSubmit={handleConversationBreakdownSubmit}
                encType="multipart/form-data"
                className="form-horizontal"
                id="selection-myform"
              >
                <div className="mb-3 row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                  ></label>
                  <div className="col-md-10">
                    <select
                      required
                      value={selectedMonth}
                      name="calculation_month"
                      className="form-control"
                      onChange={(e) =>
                        getConversationBreakdownByMonth(e.target.value)
                      }
                    >
                      <option value={"0"}>Select Month</option>
                      <option value={"01"}>January</option>
                      <option value={"02"}>February</option>
                      <option value={"03"}>March</option>
                      <option value={"04"}>April</option>
                      <option value={"05"}>May</option>
                      <option value={"06"}>June</option>
                      <option value={"07"}>July</option>
                      <option value={"08"}>August</option>
                      <option value={"09"}>September</option>
                      <option value={"10"}>October</option>
                      <option value={"11"}>November</option>
                      <option value={"12"}>December</option>
                    </select>
                  </div>
                </div>

                <input
                  type={"hidden"}
                  name="id"
                  value={conversation.id}
                ></input>
                <input
                  type={"hidden"}
                  name="conversion_rate"
                  value={
                    props.investment
                      ? props.investment.customer_conversion_calculation
                          .conversation_rate
                      : 0
                  }
                ></input>

                <div className="mb-3 row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                  >
                    New Deals Closed
                  </label>
                  <div className="col-md-4">
                    <input
                      required
                      className="form-control"
                      type={"number"}
                      min={0}
                      onChange={(e) =>
                        setConversation({
                          ...conversation,
                          ["new_customers"]: e.target.value,
                        })
                      }
                      name="new_customers"
                      value={conversation.new_customers}
                    />
                  </div>

                  <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                  >
                    Missed Opportunities
                  </label>
                  <div className="col-md-4">
                    <input
                      required
                      type={"number"}
                      min={0}
                      onChange={(e) =>
                        setConversation({
                          ...conversation,
                          ["lost_leads"]: e.target.value,
                        })
                      }
                      className="form-control"
                      name="lost_leads"
                      value={conversation.lost_leads}
                    />
                  </div>
                </div>

                <div className="mb-3 row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                  >
                    New Leads Open
                  </label>
                  <div className="col-md-4">
                    <input
                      required
                      className="form-control"
                      type={"number"}
                      min={0}
                      onChange={(e) =>
                        setConversation({
                          ...conversation,
                          ["new_leads"]: e.target.value,
                        })
                      }
                      name="new_leads"
                      value={conversation.new_leads}
                    />
                  </div>

                  <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                  >
                    Average Sales Price
                  </label>
                  <div className="col-md-4">
                    <div className="input-group mb-3 ChangeWidthMain w-100">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          {props.currency}
                        </span>
                      </div>
                      <input
                        required
                        type={"number"}
                        min={0}
                        onChange={(e) =>
                          setConversation({
                            ...conversation,
                            ["avg_sales_price"]: e.target.value,
                          })
                        }
                        className="form-control"
                        name="avg_sales_price"
                        value={
                          conversation.avg_sales_price == 0
                            ? props.investment?.customer_conversion_calculation
                                .avg_ticket_price
                            : conversation.avg_sales_price
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="mb-3 row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-3 col-form-label"
                  >
                    Phone Calls
                  </label>
                  <div className="col-md-3">
                    <input
                      required
                      min={0}
                      className="form-control"
                      type={"number"}
                      onChange={(e) =>
                        setConversation({
                          ...conversation,
                          ["phone_calls"]: e.target.value,
                        })
                      }
                      name="phone_calls"
                      value={conversation.phone_calls}
                    />
                  </div>

                  <label
                    htmlFor="example-text-input"
                    className="col-md-3 col-form-label"
                  >
                    Social Media
                  </label>
                  <div className="col-md-3">
                    <input
                      required
                      min={0}
                      type={"number"}
                      onChange={(e) =>
                        setConversation({
                          ...conversation,
                          ["social_media"]: e.target.value,
                        })
                      }
                      className="form-control"
                      name="social_media"
                      value={conversation.social_media}
                    />
                  </div>
                </div>

                <div className="mb-3 row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-3 col-form-label"
                  >
                    Web Forms
                  </label>
                  <div className="col-md-3">
                    <input
                      required
                      min={0}
                      className="form-control"
                      type={"number"}
                      onChange={(e) =>
                        setConversation({
                          ...conversation,
                          ["web_forms"]: e.target.value,
                        })
                      }
                      name="web_forms"
                      value={conversation.web_forms}
                    />
                  </div>

                  <label
                    htmlFor="example-text-input"
                    className="col-md-3 col-form-label"
                  >
                    PPC Advertising
                  </label>
                  <div className="col-md-3">
                    <input
                      required
                      min={0}
                      type={"number"}
                      onChange={(e) =>
                        setConversation({
                          ...conversation,
                          ["ppc_advertising"]: e.target.value,
                        })
                      }
                      className="form-control"
                      name="ppc_advertising"
                      value={conversation.ppc_advertising}
                    />
                  </div>
                </div>

                <div className="mb-3 row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-3 col-form-label"
                  >
                    Word of Mouth
                  </label>
                  <div className="col-md-3">
                    <input
                      required
                      min={0}
                      className="form-control"
                      type={"number"}
                      onChange={(e) =>
                        setConversation({
                          ...conversation,
                          ["word_of_mouth"]: e.target.value,
                        })
                      }
                      name="word_of_mouth"
                      value={conversation.word_of_mouth}
                    />
                  </div>

                  <label
                    htmlFor="example-text-input"
                    className="col-md-3 col-form-label"
                  >
                    Web Search (Organic)
                  </label>
                  <div className="col-md-3">
                    <input
                      required
                      min={0}
                      type={"number"}
                      onChange={(e) =>
                        setConversation({
                          ...conversation,
                          ["web_search"]: e.target.value,
                        })
                      }
                      className="form-control"
                      name="web_search"
                      value={conversation.web_search}
                    />
                  </div>
                </div>

                <div className="mb-3 row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-3 col-form-label"
                  >
                    Referral
                  </label>
                  <div className="col-md-3">
                    <input
                      required
                      min={0}
                      className="form-control"
                      type={"number"}
                      onChange={(e) =>
                        setConversation({
                          ...conversation,
                          ["referral"]: e.target.value,
                        })
                      }
                      name="referral"
                      value={conversation.referral}
                    />
                  </div>

                  <label
                    htmlFor="example-text-input"
                    className="col-md-3 col-form-label"
                  >
                    Email Referral (Exisiting Customer)
                  </label>
                  <div className="col-md-3">
                    <input
                      required
                      min={0}
                      type={"number"}
                      onChange={(e) =>
                        setConversation({
                          ...conversation,
                          ["email_referral"]: e.target.value,
                        })
                      }
                      className="form-control"
                      name="email_referral"
                      value={conversation.email_referral}
                    />
                  </div>
                </div>

                <div className="mb-3 row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-3 col-form-label"
                  >
                    Partnership
                  </label>
                  <div className="col-md-3">
                    <input
                      required
                      min={0}
                      className="form-control"
                      type={"number"}
                      onChange={(e) =>
                        setConversation({
                          ...conversation,
                          ["partnership"]: e.target.value,
                        })
                      }
                      name="partnership"
                      value={conversation.partnership}
                    />
                  </div>

                  <label
                    htmlFor="example-text-input"
                    className="col-md-3 col-form-label"
                  >
                    Email Campaign (New Customer)
                  </label>
                  <div className="col-md-3">
                    <input
                      required
                      min={0}
                      type={"number"}
                      onChange={(e) =>
                        setConversation({
                          ...conversation,
                          ["email_campaign"]: e.target.value,
                        })
                      }
                      className="form-control"
                      name="email_campaign"
                      value={conversation.email_campaign}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-8">
                    <button
                      type="submit"
                      className="btn btn-primary pull-right w-50"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default Roi;
