import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Row, Col, Collapse, Container } from "reactstrap";

import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import classname from "classnames";

import {
  ApiUrl,
  logoLightPng,
  logoLightSmallPng,
  PusherKey,
  HostUrl,
} from "../../config.js";
import { bcrypt, hasRole, logout, uInfo, uToken } from "../../useToken";
import Pusher from "pusher-js";
import swal from "sweetalert";

const Header = (props) => {
  const [menu, setMenu] = useState(false);
  const [createmenu, setCreateMenu] = useState(false);
  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    var body = document.body;
    body.classList.toggle("vertical-collpsed");
    body.classList.toggle("sidebar-enable");
  }

  useEffect(() => {
    const pusher = new Pusher(PusherKey, {
      cluster: "mt1",
    });
    const channel1 = pusher.subscribe("my-channel");
    channel1.bind("user-changed", function (data) {
      console.log(data.user_id, "user-changed");
      console.log(uInfo().user_id, "user-changed");
      console.log(data.token, "user-changed");
      console.log(uInfo().token, "user-changed");
      if (
        data.user_id != uInfo().user_id &&
        data.unique_key == localStorage.getItem("switch_unique_key")
      ) {
        setTimeout(() => {
          swal({
            title: "Switched Successfully",
            text: "Your account has been switched. Click OK to continue.",
            icon: "success",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showCancelButton: false,
            confirmButtonText: "OK",
          }).then(() => {
            window.location.href = `${HostUrl}/settings/profile/`;
          });
        }, 2000);
      }
    });

    return () => {
      pusher.unsubscribe("my-channel");
    };
  }, []);
  return (
    <React.Fragment>
      <div className="navbar-header">
        <Container fluid className="px-0">
          <Col className="nav-bg px-0 d-flex space-between">
            <div className="float-start">
              <div className="navbar-brand-box d-lg-none d-inline-block">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm pl-2">
                    <img src={logoLightSmallPng} alt="" height="45" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLightPng} alt="" height="45" />
                  </span>
                </Link>
              </div>
              <button
                type="button"
                className="btn btn-sm px-3 font-size-24 d-lg-none header-item waves-effect waves-light"
                data-toggle="collapse"
                onClick={() => {
                  setCreateMenu(!createmenu);
                }}
                data-target="#topnav-menu-content"
              >
                <i className="mdi mdi-menu"></i>
              </button>
            </div>
            <div className="top_navbar">
              <div className="container-fluid">
                <div className="topnav">
                  <nav
                    className="navbar navbar-light navbar-expand-lg topnav-menu"
                    id="navigation"
                  >
                    <Collapse
                      isOpen={props.leftMenu}
                      className="navbar-collapse"
                      id="topnav-menu-content"
                    >
                      <ul className="navbar-nav">
                        {/* <li className="nav-item">
                          <Link to="/dashboard" className="nav-link">
                            {"Dashboard"}
                          </Link>
                        </li> */}
                        {hasRole(uInfo(), ["master_users"]) && (
                          <li className="nav-item dropdown">
                            <Link
                              to="#"
                              className="nav-link dropdown-toggle arrow-none"
                            >
                              {"User"}
                              <i className="mdi mdi-chevron-down"></i>
                            </Link>
                            <div
                              className={classname(
                                "dropdown-menu dropdown-menu-left",
                                {}
                              )}
                            >
                              {hasRole(uInfo(), ["coaches"]) && (
                                <Link
                                  to="/users/admin/coach"
                                  className="dropdown-item"
                                >
                                  {"Coaches"}
                                </Link>
                              )}
                              {hasRole(uInfo(), ["staff"]) && (
                                <Link
                                  to="/users/staff"
                                  className="dropdown-item"
                                >
                                  {"Team Admin"}
                                </Link>
                              )}
                              {hasRole(uInfo(), ["clients"]) && (
                                <Link
                                  to="/users/client/list"
                                  className="dropdown-item"
                                >
                                  {"Clients"}
                                </Link>
                              )}
                              {hasRole(uInfo(), ["leads"]) && (
                                <Link
                                  to="/users/lead/list"
                                  className="dropdown-item"
                                >
                                  {"Leads"}
                                </Link>
                              )}
                              {/* {hasRole(uInfo(), ["from_email_setting_request"]) && (
                                <Link to="/users/fromemail/request" className="dropdown-item">
                                  {"From email configuration requests"}
                                </Link>
                              )} */}
                              {hasRole(uInfo(), ["permission_library"]) && (
                                <Link
                                  to="/users/permissionLibrary/list"
                                  className="dropdown-item"
                                >
                                  {"Permission Library"}
                                </Link>
                              )}
                              {hasRole(uInfo(), ["clone_coach_settings"]) && (
                                <Link
                                  to="/users/clone/settings"
                                  className="dropdown-item"
                                >
                                  {"Clone Coach Settings"}
                                </Link>
                              )}
                              {hasRole(uInfo(), ["settings_domain"]) && (
                                <Link
                                  to="/settings/domain"
                                  className="dropdown-item"
                                >
                                  {"Websites"}
                                </Link>
                              )}
                            </div>
                          </li>
                        )}

                        {hasRole(uInfo(), ["plans"]) &&
                          hasRole(uInfo(), ["coach_packages"]) && (
                            <li className="nav-item dropdown">
                              <Link
                                to="/packages/list/Coach"
                                className="nav-link dropdown-toggle arrow-none"
                              >
                                {"Plans"}
                              </Link>
                            </li>
                          )}

                        {hasRole(uInfo(), ["credits"]) &&
                          hasRole(uInfo(), ["consume_criteria"]) && (
                            <li className="nav-item dropdown">
                              <Link
                                to="/credits/consume/criteria"
                                className="nav-link dropdown-toggle arrow-none"
                              >
                                {"Credits"}
                              </Link>
                            </li>
                          )}

                        <div className="navbar-brand-box d-inline-block centerr-logo mx-3">
                          <Link to="/" className="logo logo-dark">
                            <span className="logo-sm pl-2">
                              <img src={logoLightSmallPng} alt="" height="45" />
                            </span>
                            <span className="logo-lg">
                              <img src={logoLightPng} alt="" height="45" />
                            </span>
                          </Link>
                        </div>

                        {hasRole(uInfo(), ["coaching"]) && (
                          <li className="nav-item dropdown">
                            <Link
                              to="#"
                              className="nav-link dropdown-toggle arrow-none"
                            >
                              {"Coaching"}
                              <i className="mdi mdi-chevron-down"></i>
                            </Link>
                            <div
                              className={classname(
                                "dropdown-menu dropdown-menu-left",
                                {}
                              )}
                            >
                              {hasRole(uInfo(), ["training"]) && (
                                <Link to="/trainings" className="dropdown-item">
                                  {"Training"}
                                </Link>
                              )}

                              {/* {hasRole(uInfo(), ["training"]) && (
                                <Link to="/training" className="dropdown-item">
                                  {"Training"}
                                </Link>
                              )} */}

                              {/* {hasRole(uInfo(), ["resources"]) && (
                                <Link to="/resources" className="dropdown-item">
                                  {"Resources"}
                                </Link>
                              )} */}

                              {hasRole(uInfo(), ["resources"]) && (
                                <Link
                                  to={`/resourcesDrive/my-resources/${bcrypt(
                                    uInfo().user_id
                                  )}`}
                                  className="dropdown-item"
                                >
                                  {"Resource Center"}{" "}
                                  <i className="fas fa-arrow-up"></i>
                                </Link>
                              )}

                              {hasRole(uInfo(), ["tracker_steps"]) && (
                                <Link to="/tracker" className="dropdown-item">
                                  {"Tracker Step"}
                                </Link>
                              )}

                              {hasRole(uInfo(), ["assessment_questions"]) && (
                                <Link
                                  to="/assessment/questions"
                                  className="dropdown-item"
                                >
                                  {"Assessment Questions"}
                                </Link>
                              )}
                            </div>
                          </li>
                        )}

                        {hasRole(uInfo(), ["reports"]) && (
                          <li className="nav-item dropdown">
                            <Link
                              to="#"
                              className="nav-link dropdown-toggle arrow-none"
                            >
                              {"Reports"}
                              <i className="mdi mdi-chevron-down"></i>
                            </Link>
                            <div
                              className={classname(
                                "dropdown-menu dropdown-menu-left",
                                {}
                              )}
                            >
                              {hasRole(uInfo(), ["activity_log"]) && (
                                <Link
                                  to="/coach/activity/log"
                                  className="dropdown-item"
                                >
                                  {"Activity Log"}
                                </Link>
                              )}
                              {hasRole(uInfo(), ["twillio_usagelog"]) && (
                                <Link
                                  to="/twillio/usagelog"
                                  className="dropdown-item"
                                >
                                  {"Twilio Usage Log"}
                                </Link>
                              )}
                              {hasRole(uInfo(), ["credit_consumption_log"]) && (
                                <Link
                                  to="/credit/consumptionlog"
                                  className="dropdown-item"
                                >
                                  {"Credit Consumption"}
                                </Link>
                              )}
                              {hasRole(uInfo(), ["coach_login_history"]) && (
                                <Link
                                  to="/coach/login/history"
                                  className="dropdown-item"
                                >
                                  {"Coach Login History"}
                                </Link>
                              )}
                              {hasRole(uInfo(), ["coach_subscription_log"]) && (
                                <Link
                                  to="/coach/subscription/log"
                                  className="dropdown-item"
                                >
                                  {"Revenue Subscription"}
                                </Link>
                              )}
                              {hasRole(uInfo(), [
                                "coach_nonsubscription_log",
                              ]) && (
                                <Link
                                  to="/coach/nonsubscription/log"
                                  className="dropdown-item"
                                >
                                  {"Revenue (Non-Subscription)"}
                                </Link>
                              )}

                              {hasRole(uInfo(), ["invoices_lists"]) && (
                                <Link
                                  to="/coach/invoice/log"
                                  className="dropdown-item"
                                >
                                  {"Transaction History"}
                                </Link>
                              )}
                            </div>
                          </li>
                        )}

                        {hasRole(uInfo(), ["cms"]) && (
                          <li className="nav-item dropdown">
                            <Link
                              to="#"
                              className="nav-link dropdown-toggle arrow-none"
                            >
                              {"CMS"}
                              <i className="mdi mdi-chevron-down"></i>
                            </Link>
                            <div
                              className={classname(
                                "dropdown-menu dropdown-menu-left",
                                {}
                              )}
                            >
                              {hasRole(uInfo(), ["disclaimer"]) && (
                                <Link
                                  to="/cms/disclaimer"
                                  className="dropdown-item"
                                >
                                  {"Disclaimer"}
                                </Link>
                              )}
                              {hasRole(uInfo(), ["terms_of_service"]) && (
                                <Link
                                  to="/cms/termsofservice"
                                  className="dropdown-item"
                                >
                                  {"Terms Of Service"}
                                </Link>
                              )}
                              {hasRole(uInfo(), ["privacy_policy"]) && (
                                <Link
                                  to="/cms/privacypolicy"
                                  className="dropdown-item"
                                >
                                  {"Privacy Policy"}
                                </Link>
                              )}
                              {hasRole(uInfo(), ["default_template"]) && (
                                <Link
                                  to="/default/template"
                                  className="dropdown-item"
                                >
                                  {"Default Notification Templates"}
                                </Link>
                              )}

                              {hasRole(uInfo(), ["landing_page"]) && (
                                <Link
                                  to="/landing/page"
                                  className="dropdown-item"
                                >
                                  {"Landing Page"}
                                </Link>
                              )}
                              <Link
                                to="/settings/proglobal/pages"
                                className="dropdown-item"
                              >
                                {"ProGlobal CMS"}
                              </Link>
                            </div>
                          </li>
                        )}
                      </ul>
                    </Collapse>
                  </nav>
                </div>
              </div>
            </div>
            <div className="float-end">
              <Link
                to={"/chat"}
                className="btn header-item noti-icon waves-effect display-inline"
                title="Incoming Chat Message"
              >
                <i className="ion ion-md-chatboxes"></i>
                {props.readCount > 0 && (
                  <span
                    className="badge bg-danger rounded-pill"
                    id={"total_read_count"}
                  >
                    {props.readCount}
                  </span>
                )}
              </Link>

              {/* d-lg-inline-block */}
              <div className="dropdown d-none  none">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  onClick={() => {
                    toggleFullscreen();
                  }}
                  data-toggle="fullscreen"
                >
                  <i className="mdi mdi-fullscreen font-size-24"></i>
                </button>
              </div>

              <ProfileMenu />
            </div>
          </Col>
        </Container>
      </div>
      <Row>
        <Col sm={12}>
          <div className="top-navigation px-3">
            <Collapse
              isOpen={createmenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link to="/dashboard" className="nav-link">
                    <span className="DashIcon">
                      <i className="ti-dashboard"></i>
                    </span>{" "}
                    <span>{"Dashboard"}</span>
                  </Link>
                </li>

                {hasRole(uInfo(), ["master_users"]) && (
                  <li className="nav-item dropdown">
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setMenu(menu == "master_users" ? "" : "master_users");
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      {"User"} <i className="mdi mdi-chevron-right"></i>
                    </Link>
                    <div
                      className={`dropdown-menu dropdown-menu-left ${
                        menu == "master_users" ? "show" : ""
                      }`}
                    >
                      {hasRole(uInfo(), ["coaches"]) && (
                        <Link to="/users/admin/coach" className="dropdown-item">
                          {"Coaches"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["staff"]) && (
                        <Link to="/users/staff" className="dropdown-item">
                          {"Team Admin"}
                        </Link>
                      )}

                      {hasRole(uInfo(), ["permission_library"]) && (
                        <Link
                          to="/users/permissionLibrary/list"
                          className="dropdown-item"
                        >
                          {"Permission Library"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["clone_coach_settings"]) && (
                        <Link
                          to="/users/clone/settings"
                          className="dropdown-item"
                        >
                          {"Clone Coach Settings"}
                        </Link>
                      )}
                    </div>
                  </li>
                )}

                {hasRole(uInfo(), ["plans"]) &&
                  hasRole(uInfo(), ["coach_packages"]) && (
                    <li className="nav-item dropdown">
                      <Link
                        to="/packages/list/Coach"
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        {"Plans"}
                      </Link>
                    </li>
                  )}

                {hasRole(uInfo(), ["credits"]) &&
                  hasRole(uInfo(), ["consume_criteria"]) && (
                    <li className="nav-item dropdown">
                      <Link
                        to="/credits/consume/criteria"
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        {"Credits"}
                      </Link>
                    </li>
                  )}

                {hasRole(uInfo(), ["coaching"]) && (
                  <li className="nav-item dropdown">
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setMenu(menu == "coaching" ? "" : "coaching");
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <span className="DashIcon">
                        <i className="mdi mdi-bullhorn"></i>
                      </span>{" "}
                      <span>
                        {" "}
                        {"Coaching"} <i className="mdi mdi-chevron-down"></i>
                      </span>
                    </Link>
                    <div
                      className={`dropdown-menu dropdown-menu-left ${
                        menu == "coaching" ? "show" : ""
                      }`}
                    >
                      {/* {hasRole(uInfo(), ["training"]) && (
                        <Link to="/training" className="dropdown-item">
                          {"Training"}
                        </Link>
                      )} */}

                      {/* {hasRole(uInfo(), ["resources"]) && (
                        <Link to="/resources" className="dropdown-item">
                          {"Resources"}
                        </Link>
                      )} */}

                      {hasRole(uInfo(), ["resources"]) && (
                        <Link
                          to={`/resourcesDrive/my-resources/${bcrypt(
                            uInfo().user_id
                          )}`}
                          className="dropdown-item"
                        >
                          {"Resource Center"}{" "}
                          <i className="fas fa-arrow-up"></i>
                        </Link>
                      )}

                      {hasRole(uInfo(), ["training"]) && (
                        <Link to="/trainings" className="dropdown-item">
                          {"Training"}
                        </Link>
                      )}

                      {hasRole(uInfo(), ["tracker_steps"]) && (
                        <Link to="/tracker" className="dropdown-item">
                          {"Tracker Step"}
                        </Link>
                      )}
                    </div>
                  </li>
                )}
                {hasRole(uInfo(), ["reports"]) && (
                  <li className="nav-item dropdown">
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setMenu(menu == "reports" ? "" : "reports");
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <span className="DashIcon">
                        <i className="mdi mdi-chart-bar"></i>{" "}
                      </span>{" "}
                      <span>
                        {" "}
                        {"Reports"} <i className="mdi mdi-chevron-down"></i>
                      </span>
                    </Link>
                    <div
                      className={`dropdown-menu dropdown-menu-left ${
                        menu == "reports" ? "show" : ""
                      }`}
                    >
                      {hasRole(uInfo(), ["activity_log"]) && (
                        <Link
                          to="/coach/activity/log"
                          className="dropdown-item"
                        >
                          {"Activity Log"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["twillio_usagelog"]) && (
                        <Link to="/twillio/usagelog" className="dropdown-item">
                          {"Twilio Usage Log"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["credit_consumption_log"]) && (
                        <Link
                          to="/credit/consumptionlog"
                          className="dropdown-item"
                        >
                          {"Credit Consumption"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["coach_login_history"]) && (
                        <Link
                          to="/coach/login/history"
                          className="dropdown-item"
                        >
                          {"Coach Login History"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["coach_subscription_log"]) && (
                        <Link
                          to="/coach/subscription/log"
                          className="dropdown-item"
                        >
                          {"Revenue Subscription"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["coach_nonsubscription_log"]) && (
                        <Link
                          to="/coach/nonsubscription/log"
                          className="dropdown-item"
                        >
                          {"Revenue (Non-Subscription)"}
                        </Link>
                      )}
                    </div>
                  </li>
                )}
                {hasRole(uInfo(), ["cms"]) && (
                  <li className="nav-item dropdown">
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setMenu(menu == "cms" ? "" : "cms");
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <span className="DashIcon">
                        <i className="mdi mdi-file-find"></i>
                      </span>{" "}
                      <span>
                        {" "}
                        {"CMS"} <i className="mdi mdi-chevron-down"></i>
                      </span>
                    </Link>
                    <div
                      className={`dropdown-menu dropdown-menu-left ${
                        menu == "cms" ? "show" : ""
                      }`}
                    >
                      {hasRole(uInfo(), ["disclaimer"]) && (
                        <Link to="/cms/disclaimer" className="dropdown-item">
                          {"Disclaimer"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["terms_of_service"]) && (
                        <Link
                          to="/cms/termsofservice"
                          className="dropdown-item"
                        >
                          {"Terms Of Service"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["privacy_policy"]) && (
                        <Link to="/cms/privacypolicy" className="dropdown-item">
                          {"Privacy Policy"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["default_template"]) && (
                        <Link to="/default/template" className="dropdown-item">
                          {"Default Notification Templates"}
                        </Link>
                      )}
                    </div>
                  </li>
                )}
              </ul>
            </Collapse>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Header;
