import React, { useState, useEffect, useRef } from "react";
import Loader from "react-loader";
import { ApiUrl } from "../../config";
import { bcrypt, dcrypt, uInfo, uToken } from "../../useToken";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link, useHistory, useLocation } from "react-router-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import swal from "sweetalert";
import Slidewithcontrol from "./slidewithcontrol";
import {
  Modal,
  Card,
  CardBody,
  Button,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvFeedback,
  AvInput,
} from "availity-reactstrap-validation";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import Froala from "../Froala";

require("highcharts/modules/exporting")(Highcharts);
const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const Implementation = (props) => {
  // console.log("Implementation props ", props.currency)

  const queryParms = useQuery();
  const history = useHistory();
  const [scriptBody, setScriptBody] = useState("");

  const [loaded, setLoaded] = useState(false);

  const [toggleSwitch, settoggleSwitch] = useState(false);
  const [toggle1Switch, settoggle1Switch] = useState(false);
  const [loadWizard, setreloadwizard] = useState(true);
  const [financial_records, setFinancialRecords] = useState([]);
  const [records, setRecords] = useState({
    date: "",
    noweeks: "",
    dates: [],
    records: [],
  });
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
  const [finicialModal, setFinicialModal] = useState(false);
  const [financial, setFinicial] = useState({
    id: 0,
    total_revenue: 0,
    gross_profit: 0,
    net_profit: 0,
  });
  const [modal, setModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [revenueLoaded, setRevenueLoaded] = useState(true);
  const [step, setStep] = useState({});
  const [action_results, setActionResults] = useState([]);
  const [action_note_results, setActionNoteResults] = useState([]);
  const [todoLists, setTodoLists] = useState([]);
  const [randomValue, setRandomValue] = useState(
    queryParms.get("tracker_step") ? queryParms.get("tracker_step") - 1 : 0
  );

  const [todoModal, setTodoModal] = useState(false);
  const [todo, setTodo] = useState({});

  const [stepSummary, setStepSummary] = useState({
    cat_name: "",
    completed_step: "",
    total_step: "",
  });
  useEffect(() => {
    getImplementation();
    getFinancialBreakdown();
    getActionStepsResults();
    getTodoList();
  }, []);
  const getFinancialBreakdown = () => {
    fetch(`${ApiUrl}` + "get/profitCenter/financialBreakdown", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        id: props.assessment,
      }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          console.log(response.data.records.net_profit, "daata");
          setFinancialRecords(response.data.records);
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
          setLoaded(true);
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
        setLoaded(true);
      });
  };
  const getImplementation = () => {
    fetch(`${ApiUrl}` + "assessments/implementation/flow", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        id: props.assessment,
        slug: props.slug,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setRecords(response.data);
          setLoaded(true);
        } else {
          setLoaded(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoaded(true);
      });
  };

  const triggerActiveIndex = (activeIndex) => {
    setRandomValue(activeIndex);
  };
  const customClass = ["add_quarterly_reviews", "initial_planning_meeting"];

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = [...records.records];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;

    setRecords((prevState) => ({
      ...prevState,
      records: copyListItems,
    }));

    // this.setState({
    //     records: copyListItems
    // });
    updateSequence(copyListItems);
  };

  const updateSequence = (items_arr) => {
    fetch(`${ApiUrl}` + "update/implementation/order", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ items_arr: items_arr }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          // toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const impWeekUpdation = (event) => {
    event.preventDefault();
    fetch(`${ApiUrl}` + "update/implementation/week", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        imp_id: event.target.name,
        imp_weeks: event.target.value,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          getImplementation();
          // toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getFinancialBreakdownByMonth = (month) => {
    setRevenueLoaded(false);
    fetch(`${ApiUrl}` + "get/financial/breackdown/month", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ assessment_id: props.assessment, month: month }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setFinicial(response.data.record);
          setRevenueLoaded(true);
          // toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
          setRevenueLoaded(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setRevenueLoaded(true);
      });
  };

  const closeFinanicalModal = () => {
    setFinicialModal(false);
    setFinicial({
      id: 0,
      total_revenue: 0,
      gross_profit: 0,
      net_profit: 0,
    });
  };
  const handleFinancialBreakdownSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();
    submitData.append("assessment_id", props.assessment);
    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }

    fetch(`${ApiUrl}` + "submit/financial/breakdown", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          getFinancialBreakdown();
          props.getAssessment();
          closeFinanicalModal();
          swal({
            title: response.message,
            icon: "success",
          });
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const renderTd = () => {
    let count = -1;
    let continues = 0;
    for (const i of [1, 2, 3]) {
    }
    return records.records.map((row, r) => {
      count = records.records[r - 1]
        ? (count = count + records.records[r - 1].imp_weeks)
        : (count = 0);
      return (
        <tr
          key={"mair" + r}
          // onDragStart={(e) => dragStart(e, r)}
          // onDragEnter={(e) => dragEnter(e, r)}
          // onDragEnd={drop}
          // draggable
        >
          <td className="implementation-td">
            {" "}
            <div
              style={{ textAlign: "left", paddingLeft: "10px" }}
              className={`text-transform-capitalize ${
                customClass.includes(row.type_key) ? "meeting" : ""
              }`}
            >
              {!customClass.includes(row.type_key) ? (
                <>
                  <Link
                    to={`/assessment/${bcrypt(row.assessment_id)}/${
                      row.type_key
                    }`}
                  >
                    {row.cat_name}
                  </Link>
                </>
              ) : (
                row.cat_name
              )}

              {/* <Link to={`/assessment/${bcrypt(row.assessment_id)}/implementation/${bcrypt(10)}/${row.type_key}`}>{row.type_key.replaceAll('_', ' ')}</Link> */}

              <span className="pull-right mr-5">{row.percentage}%</span>
            </div>
          </td>
          <td className="TrackerAssment">
            <select
              disabled
              className="form-control"
              name={`${row.imp_id}`}
              value={row.imp_weeks}
              onChange={(e) => impWeekUpdation(e)}
            >
              {/* <option value={0}>0</option> */}
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
              <option value={11}>11</option>
              <option value={12}>12</option>
              <option value={13}>13</option>
              <option value={14}>14</option>
              <option value={15}>15</option>
              <option value={16}>16</option>
              <option value={17}>17</option>
              <option value={18}>18</option>
              <option value={19}>19</option>
              <option value={20}>20</option>
            </select>

            {/* <input type={'number'} className="form_control" name={`${row.imp_id}`}
                value={row.imp_weeks} onChange={(e) => impWeekUpdation(e)}></input>  */}
          </td>
          {[...Array(Number(records.noweeks))].map((x, i) => {
            if (continues > 0) {
              continues = continues - 1;
              return;
            }

            if (count == i) {
              continues = row.imp_weeks - 1;
              return (
                <td colSpan={row.imp_weeks} key={"child" + i}>
                  <div
                    style={{ background: row.color_code }}
                    className={`imp-time rounded-3 ${
                      customClass.includes(row.type_key) ? "meeting" : ""
                    }`}
                  >
                    {row.imp_weeks}
                  </div>
                </td>
              );
            } else {
              return <td></td>;
            }
            // return (
            //     count == i ? (
            //         <td colSpan={row.imp_weeks} key={'child' + i}>
            //             <div className={`imp-time rounded-3 ${customClass.includes(row.type_key) ? 'meeting' : ''}`}>{row.imp_weeks}</div>
            //         </td>

            //     ) : (
            //         // i < count && (
            //         <td>{count}-- {i}</td>
            //         // )

            //     )
            // )
          })}
        </tr>
      );
    });
  };

  const getActionStepsResults = () => {
    fetch(`${ApiUrl}` + "get/assessments/action_steps_results", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        id: props.assessment,
        slug: props.slug,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setActionResults(response.data.records);
          setActionNoteResults(response.data.record_notes);
          setStepSummary({
            cat_name: response.data.cat_name,
            completed_step: response.data.completed_step,
            total_step: response.data.total_step,
          });

          if (!queryParms.get("tracker_step")) {
            setRandomValue(response.data.current_steps);
          }
          setreloadwizard(true);
          setLoading(true);
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
          setLoading(true);
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
        setLoading(true);
      });
  };

  const sendActionRequest = (event) => {
    setreloadwizard(false);
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();
    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }

    fetch(`${ApiUrl}` + "submit/action/step/request", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setreloadwizard(true);
          setStep({});
          setModal(false);
          getActionStepsResults();
          setRandomValue(randomValue);
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const actionStepComplete = (step_id) => {
    swal({
      title: "Are you sure?",
      text: "You want to perform this action",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmation) => {
      if (confirmation) {
        fetch(`${ApiUrl}` + "action/step/complete", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
          },
          body: JSON.stringify({
            id: step_id,
          }),
        })
          .then((response) => response.json())
          //Then with the data from the response in JSON...
          .then((response) => {
            if (response.status === true) {
              setreloadwizard(false);
              getActionStepsResults();
              setRandomValue(randomValue);
              setTimeout(() => {
                setreloadwizard(true);
              }, 1000);
              swal({
                title: response.message,
                icon: "success",
              });
            } else {
              swal({
                title: response.message,
                icon: "warning",
              });
            }
          })
          //Then with the error genereted...
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        document.getElementById("complete_" + step_id).checked = false;
      }
    });
  };

  const setSendStepModal = (step) => {
    // setModal(true);
    // setStep(step);

    swal({
      title: "Are you sure?",
      text: "You want to perform this action",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmation) => {
      if (confirmation) {
        fetch(`${ApiUrl}` + "submit/action/step/request", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
          },
          body: JSON.stringify({
            id: step.id,
            step: step.sr,
            description: step.description,
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.status === true) {
              // setStep({});
              // setModal(false);
              setreloadwizard(false);
              getActionStepsResults();
              setRandomValue(randomValue);
              setTimeout(() => {
                setreloadwizard(true);
              }, 1000);
              swal({
                title: response.message,
                icon: "success",
              });
            } else {
              swal({
                title: response.message,
                icon: "warning",
              });
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    });
  };
  const handleBody = async (event, values) => {
    console.log(event);

    setScriptBody(values);
  };
  const handleSubmit = async (event, values) => {
    values.assessment_id = props.assessment;
    values.cat_key = props.slug;
    values.description = scriptBody;
    fetch(`${ApiUrl}` + "add/todo", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          setTodoModal(false);
          getTodoList();
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getTodoList = () => {
    fetch(`${ApiUrl}` + "get/todo/" + props.assessment, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        cat_key: props.slug,
      }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setTodoLists(response.data.todoTists);
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const todoRemove = (id) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this action",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmation) => {
      if (confirmation) {
        fetch(`${ApiUrl}` + "todo/delete/" + id, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
          },
        })
          .then((response) => response.json())
          //Then with the data from the response in JSON...
          .then((response) => {
            if (response.status === true) {
              getTodoList();
            } else {
              toast(response.message, {
                hideProgressBar: true,
                position: "top-center",
                transition: Flip,
                className: "toast-custom-cls error",
              });
            }
          })
          //Then with the error genereted...
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    });
  };

  const todoComplete = (id) => {
    swal({
      title: "Are you sure?",
      text: "You want to perform this action",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmation) => {
      if (confirmation) {
        fetch(`${ApiUrl}` + "todo/complete/" + id, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
          },
        })
          .then((response) => response.json())
          //Then with the data from the response in JSON...
          .then((response) => {
            if (response.status === true) {
              getTodoList();
            } else {
              toast(response.message, {
                hideProgressBar: true,
                position: "top-center",
                transition: Flip,
                className: "toast-custom-cls error",
              });
            }
          })
          //Then with the error genereted...
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    });
  };
  return (
    <React.Fragment>
      <Loader loaded={loaded}></Loader>
      <div className="table-responsive WordSection1 survey card card-body ProgressTrackerImplementation pb-2">
        <div className="row">
          <div className="col-lg-6"></div>
          <div className="col-lg-6">
            <div className="GoalSwiltch ProfileToggle pull-right">
              <div className="form-check form-switch">
                <label className="form-check-label">PATHWAY TO PROFIT</label>
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitch2"
                  checked={toggleSwitch}
                  onClick={(e) => {
                    settoggle1Switch(false);
                    settoggleSwitch(!toggleSwitch);
                  }}
                />
              </div>

              <div className="form-check form-switch">
                <label className="form-check-label">FINANCIALS</label>
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitch2"
                  checked={toggle1Switch}
                  onClick={(e) => {
                    settoggleSwitch(false);
                    settoggle1Switch(!toggle1Switch);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <h6 className="h-6 d-none">Implementation starts: {records.date}</h6>

        {loaded && toggleSwitch && (
          <table className="table mt-3">
            <thead className="sub-nav-bg">
              <tr>
                <th style={{ textAlign: "left" }}>Pathway to Profit</th>
                <th style={{ textAlign: "left" }}>
                  <small>WEEKS</small>
                </th>
                {records.dates.map((row, i) => {
                  return (
                    <OverlayTrigger
                      key={`auto-${i}`}
                      placement={"auto"}
                      overlay={<Tooltip>{row.month_year}</Tooltip>}
                    >
                      <th colSpan={row.week}> {row.month} </th>
                    </OverlayTrigger>
                  );
                })}
              </tr>
            </thead>
            <tbody id="MainHTML">{renderTd()}</tbody>
          </table>
        )}

        {loaded && toggle1Switch && (
          <div className="card card-body">
            <button
              disabled={!props.canEdit ? true : false}
              onClick={() => setFinicialModal(true)}
              className="d-flex justify-content-end text-dark btn btn-link btn-sm btn-block"
            >
              <i className="fas fa-edit"></i>
            </button>

            <HighchartsReact
              highcharts={Highcharts}
              options={{
                credits: {
                  enabled: false,
                },
                exporting: {
                  enabled: false,
                },
                chart: {
                  type: "column",
                },

                title: {
                  text: "<small>" + props.name + "</small>",
                  useHTML: true,
                  align: "left",
                },
                subtitle: {
                  text: "<h3><b>Financial Breakdown</b></h3>",
                  useHTML: true,
                  align: "left",
                },
                xAxis: {
                  categories: [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                  ],
                  crosshair: true,
                },
                yAxis: {
                  title: {
                    useHTML: true,
                    text: "",
                  },
                },
                tooltip: {
                  headerFormat:
                    '<span style="font-size:10px">{point.key}</span><table>',
                  pointFormat:
                    '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
                  footerFormat: "</table>",
                  shared: true,
                  useHTML: true,
                },
                plotOptions: {
                  column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                  },
                },
                legend: {
                  align: "center",
                  x: -30,
                  verticalAlign: "top",
                  y: 25,
                  floating: true,
                  backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || "white",
                  borderColor: "#CCC",
                  borderWidth: 1,
                  shadow: false,
                },
                series: [
                  {
                    name: "Net Profit" + props.currency,
                    color: "#008037",
                    data: financial_records.net_profit,
                  },
                  {
                    name: "Gross Profit" + props.currency,
                    color: "#a0a0a0",
                    data: financial_records.gross_profit,
                  },
                  {
                    name: "Total Revenue" + props.currency,
                    color: "#00002A",
                    data: financial_records.total_revenue,
                  },
                ],
              }}
            />
          </div>
        )}

        {loading && !toggleSwitch && !toggle1Switch && (
          <div className="row">
            <div className="col-lg-6">
              <section className="breakthrough border-radius-0 justify-content-end m-0 d-inline-block ClientID">
                <div className="ClientHead d-flex  justify-content-between  fs-5 pr-15 w-100">
                  {props.name} (Progress Tracker)
                  <div className="MonthlyDropDown mb-1">
                    <select
                      value={props.slug}
                      onChange={(e) => {
                        if (e.target.value != "") {
                          history.push(
                            `/progress/tracker/${bcrypt(props.assessment)}/${
                              e.target.value
                            }`
                          );
                        }
                      }}
                      className="form-control"
                    >
                      <option value={""}>Impact Selector</option>
                      {props.path_way_progression.length > 0 &&
                        props.path_way_progression.map((row, i) => {
                          return (
                            <option value={row.cat_key}>{row.cat_name}</option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="ClientHead d-flex justify-content-start w-100">
                  <h2 className="mb-0">{stepSummary.cat_name} </h2>
                </div>
                <div className="ClientHead d-flex justify-content-end w-100 pr-40 mb-2">
                  <small>{stepSummary.completed_step} completed</small>
                </div>
              </section>

              <div className="card card-body pt-0 mt-3 pb-0">
                <div className="ProfileBreakDown PTracker ProgressTrackers OptimizeConversions">
                  {loadWizard && (
                    <Slidewithcontrol
                      randomValue={randomValue}
                      totalStep={stepSummary.total_step}
                      setSendStepModal={setSendStepModal}
                      actionStepComplete={actionStepComplete}
                      steps={action_results}
                      triggerActiveIndex={triggerActiveIndex}
                    />
                  )}
                  <select
                    onChange={(e) => {
                      let num = Number(e.target.value);
                      setreloadwizard(false);
                      setRandomValue(num);
                      setTimeout(() => {
                        setreloadwizard(true);
                      }, 1000);
                    }}
                    value={randomValue}
                  >
                    <option value={""}>Select Step</option>
                    {[...Array(stepSummary.total_step)].map((x, i) => (
                      <option value={i}>{i + 1}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <Card className="mt-3 boxescard">
                <section className="breakthrough clientProfileHeading sub-nav-bg mb-0 text-center">
                  <h5 className="mb-0">IMPACT NOTES</h5>
                </section>
                <CardBody className="ClientActionList px-2">
                  <div className="text-right mt-2 mb-1">
                    <button
                      onClick={() => setTodoModal(true)}
                      className="btn code-green"
                    >
                      <i className="fas fa-plus"></i>
                    </button>
                  </div>

                  <ul className="list-group todo-list tracker-todo">
                    {todoLists.map((list, i) => {
                      return (
                        <li className="mb-2" key={i}>
                          <div className="d-flex justify-content-between todotask">
                            <div className="list-todo">
                              {!props.canEdit && (
                                <>
                                  {list.created_by == uInfo().user_id ? (
                                    <>
                                      {list.status == 1 ? (
                                        <button
                                          title="UnMark"
                                          onClick={() => todoComplete(list.id)}
                                          className="btn btn-outline-success boder-green rounded-100"
                                        >
                                          <i className="fas fa-check"></i>
                                        </button>
                                      ) : (
                                        <button
                                          title="Mark as complete"
                                          onClick={() => todoComplete(list.id)}
                                          className="bwaves-effect waves-light btn btn-outline-dark rounded-100"
                                        ></button>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {list.status == 1 ? (
                                        <button
                                          title="UnMark"
                                          disabled
                                          className="btn btn-outline-success boder-green rounded-100"
                                        >
                                          <i className="fas fa-check"></i>
                                        </button>
                                      ) : (
                                        <button
                                          title="Mark as complete"
                                          disabled
                                          className="bwaves-effect waves-light btn btn-outline-dark rounded-100"
                                        ></button>
                                      )}
                                    </>
                                  )}
                                </>
                              )}

                              {props.canEdit && (
                                <>
                                  {list.status == 1 ? (
                                    <button
                                      title="UnMark"
                                      onClick={() => todoComplete(list.id)}
                                      className="btn btn-outline-success boder-green rounded-100"
                                    >
                                      <i className="fas fa-check"></i>
                                    </button>
                                  ) : (
                                    <button
                                      title="Mark as complete"
                                      onClick={() => todoComplete(list.id)}
                                      className="bwaves-effect waves-light btn btn-outline-dark rounded-100"
                                    ></button>
                                  )}
                                </>
                              )}

                              <p className="notes text-dark p-2">
                                <span className="font-weight-bold text-dark">
                                  {list.created_at}
                                </span>
                                <hr className="my-1" />

                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: list.description,
                                  }}
                                />
                              </p>
                            </div>

                            {!props.canEdit && (
                              <>
                                {list.created_by == uInfo().user_id ? (
                                  <>
                                    {list.status == 1 && (
                                      <button
                                        title="Remove action list"
                                        onClick={() => todoRemove(list.id)}
                                        className="btn btn-link Remove-Action"
                                      >
                                        <i className="fas fa-trash-alt"></i>
                                      </button>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {list.status == 1 && (
                                      <button
                                        title="Remove action list"
                                        disabled
                                        className="btn btn-link Remove-Action"
                                      >
                                        <i className="fas fa-trash-alt"></i>
                                      </button>
                                    )}
                                  </>
                                )}
                              </>
                            )}

                            {props.canEdit && (
                              <>
                                {list.status == 1 && (
                                  <button
                                    title="Remove action list"
                                    onClick={() => todoRemove(list.id)}
                                    className="btn btn-link Remove-Action"
                                  >
                                    <i className="fas fa-trash-alt"></i>
                                  </button>
                                )}
                              </>
                            )}

                            {!props.canEdit && (
                              <>
                                {list.created_by == uInfo().user_id ? (
                                  <>
                                    <button
                                      title="Edit"
                                      onClick={() => {
                                        setTodoModal(true);
                                        setTodo(list);
                                      }}
                                      className="btn btn-link Remove-Action ms-2"
                                    >
                                      <i className="fas fa-edit"></i>
                                    </button>
                                  </>
                                ) : (
                                  <button
                                    title="Edit"
                                    disabled
                                    className="btn btn-link Remove-Action ms-2"
                                  >
                                    <i className="fas fa-edit"></i>
                                  </button>
                                )}
                              </>
                            )}

                            {props.canEdit && (
                              <>
                                <button
                                  title="Edit"
                                  onClick={() => {
                                    setTodoModal(true);
                                    setTodo(list);
                                  }}
                                  className="btn btn-link Remove-Action ms-2"
                                >
                                  <i className="fas fa-edit"></i>
                                </button>
                              </>
                            )}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </CardBody>
              </Card>
            </div>
          </div>
        )}

        <Modal
          isOpen={finicialModal}
          fade={true}
          size="lg"
          className="FinancialBreakdown"
          toggle={() => closeFinanicalModal()}
          centered={true}
        >
          <div className="card card-body">
            <div className="modalQuestion ">
              <button className="mb-3 btn btn-primary BtnCenter">
                Profit & Loss Statement
              </button>
              <button
                onClick={() => closeFinanicalModal()}
                className="mb-3 btn btn-link pull-right BtnCenterClose"
              >
                <i className="fas fa-times"></i>
              </button>

              <form
                method="post"
                onSubmit={handleFinancialBreakdownSubmit}
                encType="multipart/form-data"
                className="form-horizontal"
                id="selection-myform"
              >
                <div className="mb-3 row">
                  <label
                    for="example-text-input"
                    className="col-md-2 col-form-label"
                  ></label>
                  <div className="col-md-10">
                    <select
                      required
                      name="calculation_month"
                      className="form-control"
                      onChange={(e) =>
                        getFinancialBreakdownByMonth(e.target.value)
                      }
                    >
                      <option value={""}>Select Month</option>
                      <option value={"01"}>January</option>
                      <option value={"02"}>February</option>
                      <option value={"03"}>March</option>
                      <option value={"04"}>April</option>
                      <option value={"05"}>May</option>
                      <option value={"06"}>June</option>
                      <option value={"07"}>July</option>
                      <option value={"08"}>August</option>
                      <option value={"09"}>September</option>
                      <option value={"10"}>October</option>
                      <option value={"11"}>November</option>
                      <option value={"12"}>December</option>
                    </select>
                  </div>
                </div>
                <Loader loaded={revenueLoaded} />
                <input type={"hidden"} name="id" value={financial.id}></input>
                <div className="row">
                  <label
                    for="example-text-input"
                    className="col-md-2 col-form-label"
                  >
                    Total Revenue
                  </label>
                  <div className="input-group mb-3 ChangeWidthMain col-md-10">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{props.currency}</span>
                    </div>
                    <input
                      required
                      className="form-control"
                      type={"number"}
                      onChange={(e) =>
                        setFinicial({
                          ...financial,
                          ["total_revenue"]: e.target.value,
                        })
                      }
                      name="total_revenue"
                      value={financial.total_revenue}
                    />
                  </div>
                </div>

                <div className="row">
                  <label
                    for="example-text-input"
                    className="col-md-2 col-form-label"
                  >
                    Gross Profit{" "}
                  </label>
                  <div className="input-group mb-3 ChangeWidthMain col-md-10">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{props.currency}</span>
                    </div>
                    <input
                      required
                      type={"number"}
                      onChange={(e) =>
                        setFinicial({
                          ...financial,
                          ["gross_profit"]: e.target.value,
                        })
                      }
                      className="form-control"
                      name="gross_profit"
                      value={financial.gross_profit}
                    />
                  </div>
                </div>

                <div className="mb-3 row">
                  <label
                    for="example-text-input"
                    className="col-md-2 col-form-label"
                  >
                    Net Profit{" "}
                  </label>
                  <div className="input-group mb-3 ChangeWidthMain col-md-10">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{props.currency}</span>
                    </div>
                    <input
                      required
                      type={"number"}
                      onChange={(e) =>
                        setFinicial({
                          ...financial,
                          ["net_profit"]: e.target.value,
                        })
                      }
                      className="form-control"
                      name="net_profit"
                      value={financial.net_profit}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-8">
                    <button
                      type="submit"
                      disabled={!revenueLoaded}
                      className="btn btn-primary pull-right w-50"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={todoModal}
          fade={true}
          size="md"
          toggle={() => {
            setTodoModal(false);
          }}
        >
          <div className="note">
            <div className="modal-head top-heading">
              <h2> {todo.id ? "Edit" : "Add"} Impact Note </h2>
              <button
                onClick={() => {
                  setTodoModal(false);
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <ModalBody>
              <AvForm
                onValidSubmit={handleSubmit}
                // model={this.state.record}
                className="needs-validation"
              >
                <div className="mb-3">
                  {/* <AvField
                    className="form-control"
                    type="textarea"
                    name="description"
                    label=""
                    placeholder="Add a note"
                    required
                    value={todo.description}
                    rows={5}
                  /> */}
                  <Froala
                    inlineEditior={true}
                    btnId={"add-note-content-impact"}
                    content={todo.description}
                    handleSubmit={handleBody}
                    use_token={"users"}
                  />

                  {todo.id && (
                    <AvField
                      name="id"
                      type="hidden"
                      value={todo.id}
                      required
                    ></AvField>
                  )}
                </div>
                <ModalFooter className="pb-0 border-0">
                  <Button color="secondary" onClick={() => setTodoModal(false)}>
                    Cancel
                  </Button>
                  <Button
                    className="Green"
                    type="submit"
                    id="add-note-content-impact"
                  >
                    Save
                  </Button>
                </ModalFooter>
              </AvForm>
            </ModalBody>
          </div>
        </Modal>

        <Modal
          isOpen={modal}
          fade={true}
          size="lg"
          className="ActionStep"
          toggle={() => {
            setStep({});
            setModal(false);
          }}
          centered={true}
        >
          <div className="card card-body">
            <div className="modalQuestion">
              <button className="mb-3 btn btn-primary">
                ProGlobal Recommendation
              </button>
              <button
                onClick={() => {
                  setModal(false);
                  setModal(false);
                }}
                className="mb-3 btn btn-link pull-right BtnCenterClose"
              >
                <i className="fas fa-times"></i>
              </button>
              <div className="PTracker">
                {/* <ul className="TasksAsign modal-step">
                  <li key={step.sr}>
                    <div className="TaskNumber px-2">{step.sr}</div>
                    <div className="TaskDesription-Ptracker w-100">
                      <p>{step.description}</p>
                    </div>
                  </li>
                </ul>
                <button className="mb-3 btn btn-success">Client Custom Action Step</button> */}

                <form
                  method="post"
                  onSubmit={sendActionRequest}
                  encType="multipart/form-data"
                  className="form-horizontal"
                  id="selection-myform"
                >
                  <input type={"hidden"} name={"id"} value={step.id} />
                  <input type={"hidden"} name={"step"} value={step.sr} />
                  <SunEditor
                    name="description"
                    setOptions={{
                      height: 300,
                      width: "100%",
                      resizeEnable: "false",
                      buttonList: [
                        ["undo", "redo", "font", "fontSize", "formatBlock"],
                        [
                          "bold",
                          "underline",
                          "italic",
                          "strike",
                          "subscript",
                          "superscript",
                          "removeFormat",
                        ],
                        [
                          "fontColor",
                          "hiliteColor",
                          "outdent",
                          "indent",
                          "align",
                          "horizontalRule",
                          "list",
                          "table",
                        ],
                        [
                          "link",
                          "image",
                          "video",
                          "fullScreen",
                          "showBlocks",
                          "codeView",
                          "preview",
                        ],
                      ],
                      proportion: "Constrain proportions assasa",
                    }}
                    setContents={step.description}
                  />

                  <button className="btn btn-dark mt-2 pull-right">
                    Send Action Steps
                  </button>
                </form>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default Implementation;
