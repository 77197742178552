import React, { useEffect, useState } from "react"
import { v4 as uuidv4 } from "uuid"; 
import { Row, Col, Alert, Card, CardBody, Container } from "reactstrap"
import { withRouter, Link, useLocation } from "react-router-dom"
import Helmet from "react-helmet"
import { ApiUrl, ProductName, logoLightPng } from '../../config';
import { toast, ToastContainer, Flip } from 'react-toastify';
import useToken, { bcrypt, dcrypt, switchUserList } from '../../useToken';
import { useSwitchUser } from '../../useToken';
import Loader from "react-loader";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-bootstrap";
const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}
const UserToggle = props => {
    const queryParms = useQuery();
    const [users, setUsers] = useState(props.location.state.users);
    const { setToken } = useToken();
    const { setSwitchUser } = useSwitchUser();
    const [loaded, setLoaded] = useState(true);
    const [staff_user_id] = useState(queryParms.get('id') ? dcrypt(queryParms.get('id')) : 0);
  
    function handleSubmit(user_id) {
        setLoaded(false);
        let storedKey = localStorage.getItem("switch_unique_key");
        if (!storedKey) {
            storedKey = uuidv4(); // Generate new unique key
            localStorage.setItem("switch_unique_key", storedKey);
        }
  
        fetch(`${ApiUrl}` + 'login/choice', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 'user_id': user_id,'staff_user_id': staff_user_id, unique_key:storedKey }),
        })
            .then((response) => response.json())
            .then((res) => {
                if (res.status === true) {
                    localStorage.setItem("theme", res.data.theme);
                    setToken(res.data.user);
                    setSwitchUser(res.data.users);
                    window.location.href = '/dashboard';
                } else {
                    if (res.data) {
                        if (res.data.code == 302) {
                            props.history.push(`/send/account/notification/${bcrypt(res.data.user_id)}/${bcrypt(res.data.admin_id)}/${bcrypt(res.data.msg)}`)
                        } else if (res.data.code == 303) {
                            props.history.push(`/renew/subscription/${bcrypt(res.data.user_id)}/${bcrypt(res.data.admin_id)}/${bcrypt(res.data.msg)}`)
                        } else {
                            setLoaded(true);
                            toast(res.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                        }
                    } else {
                        setLoaded(true);
                        toast(res.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                    }
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error('Error:', error);
            });
    }


    return (
        <React.Fragment>
            <Helmet>
                <title>How would you like to proceed?</title>
            </Helmet>
            <ToastContainer />
            <Loader loaded={loaded}></Loader>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={10} lg={8} xl={7}>
                            <Card className="overflow-hidden">
                                <CardBody className="pt-0">
                                    <h3 className="text-center mt-5 mb-4">
                                        <Link to="/" className="d-block auth-logo">
                                            <img src={logoLightPng} alt="" height="45" className="auth-logo-dark" />
                                        </Link>
                                    </h3>
                                    <div className="p-3">
                                        <h4 className="text-muted font-size-18 mb-3 text-center">How would you like to proceed?</h4>
                                        <div className="mb-3 text-center">
                                            {

                                                users.map((user, i) => { 
                                                    return (
                                                        <div className='display-inline-block' key={i}>
                                                            <OverlayTrigger
                                                                placement="auto"
                                                                overlay={
                                                                <Tooltip> 
                                                                {user.type == "Client" && (
                                                                    "This client account exist under coach: "+user.coach_email
                                                                )}
                                                                </Tooltip>
                                                            }
                                                            >
                                                            <button
                                                                className="btn btn-primary w-sm waves-effect waves-light"
                                                                type="submit"
                                                                onClick={() => handleSubmit(user.user_id)}
                                                            >

                                                                {user.type == "WlAdmin" ? (
                                                                    "Admin"
                                                                ) : (
                                                                    user.type
                                                                )}

                                                            </button>
                                                            </OverlayTrigger>
                                                            {
                                                                users.length > i + 1 && (
                                                                    <i className='p-10'>OR</i>
                                                                )
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}


export default UserToggle;

